import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import PopOver from "../PopOver";
import NotificationPopupContent from "./NotificationPopupContent";

const PopupNotification = ({
  isShowScrolHeader,
}: {
  isShowScrolHeader?: boolean;
}) => {
  const { token, user } = useAuth();
  const [countNoti, setCountNoti] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (token)
      axios
        .request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/notifications/count",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCountNoti(res.data.count);
        });
  }, [token, isShowScrolHeader]);

  const seenAllNoti = useCallback(async () => {
    if (token)
      try {
        await axios.request({
          method: "POST",
          url: "/v1/notifications/view",
          baseURL: MEEY_REVIEW_API_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountNoti(0);
      } catch (error) {}
  }, [token]);

  useEffect(() => {
    setOpen(false);
  }, [isShowScrolHeader]);
  return user ? (
    <>
      <PopOver
        trigger="click"
        dropdownClassName="mt-2 !p-0 rounded-lg "
        onOpen={() => {
          seenAllNoti();
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        open={open}
        content={
          <div className=" bg-white rounded flex flex-col items-center justify-center w-[22.5rem] max-h-[40rem] overflow-auto">
            <NotificationPopupContent onClose={() => setOpen(false)} />
          </div>
        }>
        <div className="rounded-full bg-blue-50 w-9 h-9 flex items-center justify-center relative">
          <i className="mrv mrv-notifications text-fs-20 text-blue-500"></i>
          {countNoti ? (
            <div className="absolute top-0 -right-2 text-white bg-red-500 rounded-full text-fs-12 px-1">
              {countNoti}
            </div>
          ) : null}
        </div>
      </PopOver>
    </>
  ) : null;
};

export default PopupNotification;
