import { getListSearchHistory } from "@/api/endpoint/search/get-list-search-history";
import { saveSearchHistory } from "@/api/endpoint/search/save-search-history";
import { createFetcher } from "@/api/utils/fetch-api";
import AvatarWithName from "@/components/AvatarWithName";
import {
  MAX_LENGTH_SEARCH_INPUT,
  TIMEOUT_LOAD_MORE,
} from "@/constants/common-constant";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { NOT_FOUND_PROJECT_IMAGE } from "@/constants/image";
import { LIMIT_DATALOAD_LIST } from "@/constants/number";
import { DEFAULT_LOGO_PROJECT } from "@/constants/project-constant";
import { ProjectContext } from "@/context/project.context";
import { useAuth } from "@/hooks/useAuth";
import { ValueLocation } from "@/models/location";
import { convertAddress } from "@/utils";
import {
  lockScroll,
  unlockScroll,
} from "@/vendor/scroll-locker/useScrollLocker";
import { BaseDataListResponse, BaseItemResponseTranslate } from "Models";
import { Select } from "antd";
import unionBy from "lodash/unionBy";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
export interface BaseValue {
  _id: string;
  [key: string]: string;
}

let timeOutSearch: NodeJS.Timeout = null;

// eslint-disable-next-line react/display-name
const SearchProject = ({
  showSuffixIcon = true,
  className,
  allowClear = false,
  onChangeText,
  onPressEnter,
  valueSearch,
}: {
  showSuffixIcon?: boolean;
  className?: string;
  allowClear?: boolean;
  onPressEnter?: () => void;
  onChangeText?: (value?: string) => void;
  valueSearch?: {
    text?: string;
    location?: ValueLocation;
  };
}) => {
  const [response, setResponse] = useState<BaseDataListResponse<any>>({
    limit: LIMIT_DATALOAD_LIST,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    results: [],
  });

  const { push, query } = useRouter();
  const { loadingSendReply } = useContext(ProjectContext);

  const [valueInput, setValueInput] = useState(null);
  const [previousInput, setPreviousInput] = useState(null);
  const listDataOptionsRender = useMemo(() => {
    if (!valueInput) {
      return [];
    } else {
      return response.results;
    }
  }, [response.results, valueInput]);

  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [blur, setBlur] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  const { userDataHeader } = useAuth();

  const handleLoadMore = async () => {
    try {
      setLoading(true);
      const dataPath = `v1/projects/search`;
      const res = await createFetcher<
        BaseDataListResponse<BaseItemResponseTranslate>
      >(() => ({
        apiUrl: MEEY_REVIEW_API_URL,
      }))(
        "POST",
        dataPath,
        {
          sort: "title:asc",
          title: valueInput || "",
          page: response.page + 1,
          limit: response.limit,
        },
        { ...userDataHeader }
      );

      setResponse({
        ...res,
        results: unionBy(
          response.results,
          res.results.map((item) => item),
          "_id"
        ),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const loadData = async (params: string) => {
    const dataPath = `v1/projects/search`;
    try {
      setLoading(true);
      const res = await createFetcher<
        BaseDataListResponse<BaseItemResponseTranslate>
      >(() => ({
        apiUrl: MEEY_REVIEW_API_URL,
      }))(
        "POST",
        dataPath,
        {
          title: params,
          sort: "title:asc",
          page: 1,
          limit: response.limit,
        },
        { ...userDataHeader }
      );
      setResponse(
        res.totalResults
          ? {
              ...res,
              results: res.results,
            }
          : {
              page: 1,
              totalResults: 1,
              totalPages: 1,
              results: [],
            }
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (params?: string) => {
    if (!blur) {
      setValueInput(
        params?.trim() ? params.substring(0, MAX_LENGTH_SEARCH_INPUT) : null
      );
      setPreviousInput(null);
      if (params?.length || (!params?.length && valueInput?.length)) {
        setTyping(true);
        if (timeOutSearch) clearTimeout(timeOutSearch);
        timeOutSearch = setTimeout(() => {
          setTyping(false);
          loadData(params);
        }, TIMEOUT_LOAD_MORE);
      }
    }
  };

  useEffect(() => {
    if (query?.title) {
      setValueInput(query?.title);
      setPreviousInput(query?.title);
    }
  }, [query]);

  const handleGetListSearchHistory = async () => {
    setLoading(true);
    const res = await getListSearchHistory();
    if (res) {
      setSearchHistory(res);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (focus && !valueInput?.trim()) {
      handleGetListSearchHistory();
    }
  }, [focus, valueInput]);

  const openSearchHistoryModal = useMemo(() => {
    return searchHistory?.length && !valueInput?.trim() && focus ? true : false;
  }, [searchHistory, valueInput, focus]);

  const openSearchResultModal = useMemo(() => {
    return valueInput?.trim() && focus && previousInput !== valueInput
      ? true
      : false;
  }, [valueInput, focus, previousInput, searchHistory]);
  useEffect(() => {
    if (openSearchResultModal || openSearchHistoryModal) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [openSearchResultModal, openSearchHistoryModal]);

  const refListSearchHistory = useRef<any>(null);
  const handleSaveSearchHistory = async (project?: any) => {
    await saveSearchHistory(
      {
        keywords: project
          ? project?.title || project?.keywords
          : valueInput?.trim(),
        project: project?._id,
      },
      userDataHeader
    );
  };
  useEffect(() => {
    if (openSearchHistoryModal) {
      refListSearchHistory?.current?.scrollTo({
        top: 0,
      });
    }
  }, [openSearchHistoryModal]);

  useEffect(() => {
    onChangeText && onChangeText(valueInput);
  }, [valueInput]);

  return (
    <Select
      size="large"
      placeholder="Tìm dự án mà bạn quan tâm..."
      bordered={false}
      notFoundContent={
        !typing && !loading ? (
          <div className="flex flex-col justify-center items-center h-[221px]">
            <img
              src={NOT_FOUND_PROJECT_IMAGE}
              alt="not-found-project"
              className="mb-2"
            />
            <span className="text-fs-14 text-grey-500">
              Có 0 kết quả phù hợp.
            </span>
          </div>
        ) : (
          <div className="h-11"></div>
        )
      }
      className={`${className} ${allowClear ? "input-search-clear" : ""}`}
      allowClear={allowClear}
      suffixIcon={null}
      showSearch
      optionLabelProp="title"
      searchValue={valueInput}
      onPopupScroll={(event) => {
        const target = event.target as HTMLDivElement;
        if (target.scrollTop + target.offsetHeight + 20 > target.scrollHeight) {
          if (
            response.page < response.totalPages &&
            !loading &&
            valueInput?.trim()
          )
            handleLoadMore();
        }
      }}
      onSelect={(item) => {
        // push(`/project/${item}`);
        setValueInput(null);
      }}
      onInputKeyDown={(e) => {
        setFocus(true);
        if (e?.key === "Enter" && valueInput?.trim()) {
          handleSaveSearchHistory();
          onPressEnter
            ? onPressEnter()
            : push({
                pathname: "/search",
                query: {
                  title: valueInput,
                  city: valueSearch?.location.city?.map((e) => e._id),
                  district: valueSearch?.location.district?.map((e) => e._id),
                  ward: valueSearch?.location.ward?.map((e) => e._id),
                },
              });
          setFocus(false);
        }
      }}
      value={valueInput}
      // getPopupContainer={(trigger) => trigger.parentElement}
      filterOption={false}
      onSearch={async (params) => {
        handleSearch(params);
      }}
      onBlur={(e: any) => {
        setBlur(true);
        clearTimeout(timeOutSearch);
        setTyping(false);
        setResponse({
          page: 1,
          totalResults: 0,
          totalPages: 1,
          results: [],
        });
        setFocus(false);
        setPreviousInput(valueInput);
      }}
      onFocus={() => {
        setBlur(false);
        setFocus(true);
      }}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            {loading || typing ? (
              <div className="flex justify-center h-4  absolute  w-full bottom-0 pt-1">
                <div className={"dot-flashing"}></div>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      }}
      dropdownStyle={{
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: loading ? 16 : 8,
      }}
      listHeight={275}
      open={openSearchResultModal || openSearchHistoryModal}
      defaultActiveFirstOption={false}
      clearIcon={
        <div className="h-4 w-4 rounded-full bg-grey-500 flex items-center justify-center">
          <i className="mrv mrv-close text-fs-12 text-white"></i>
        </div>
      }
      autoFocus={false}
      rootClassName={
        listDataOptionsRender?.length || searchHistory?.length > 5
          ? "show-scroll-bar"
          : ""
      }
      ref={refListSearchHistory}
      disabled={loadingSendReply}>
      {searchHistory && !valueInput?.trim() ? (
        <Select.OptGroup
          label={
            <div className="text-base text-grey-800 font-semibold mb-1">
              Lịch sử tìm kiếm gần đây
            </div>
          }>
          {searchHistory.map((item) => {
            return (
              <Select.Option
                key={item._id}
                value={
                  item?.project
                    ? `${item.slug}-${item.shortId ? item.shortId : item._id}`
                    : item?.keywords
                }
                title={item.keywords}>
                {item?.project ? (
                  <Link
                    onClick={() => {
                      setSearchHistory([]);
                      handleSaveSearchHistory(item?.project);
                      refListSearchHistory?.current.blur();
                      setFocus(false);
                    }}
                    href={`/project/${item.project.slug}-${
                      item.project.shortId ? item.project.shortId : item._id
                    }`}>
                    <AvatarWithName
                      name={item?.project.title}
                      textClass="text-fs-14 font-medium text-grey-800"
                      subTitleClass="text-fs-12 font-normal text-grey-500"
                      subTitle={convertAddress(item?.project)}
                      src={
                        item?.project.logo?.uri ?? `/${DEFAULT_LOGO_PROJECT}`
                      }
                      imageClass="!object-contain"
                      imageProps={{
                        resizeOnFly: item?.project.logo?.uri ? true : false,
                        resizeOptions: {
                          width: 0,
                          height: 60,
                        },
                      }}
                    />
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      setSearchHistory([]);
                      handleSaveSearchHistory({
                        keywords: item?.keywords,
                      });
                      push({
                        pathname: "/search",
                        query: {
                          title: item?.keywords,
                          city: valueSearch?.location.city?.map((e) => e._id),
                          district: valueSearch?.location.district?.map(
                            (e) => e._id
                          ),
                          ward: valueSearch?.location.ward?.map((e) => e._id),
                        },
                      });
                    }}
                    className="cursor-pointer">
                    <div className="flex items-center gap-3">
                      <div className="w-9 h-6 shrink-0 flex justify-center">
                        <img
                          src="/images/timer.svg"
                          alt="time"
                          className="w-6 h-6"
                        />
                      </div>
                      <span className="text-base text-grey-800 font-medium">
                        {item?.keywords}
                      </span>
                    </div>
                  </div>
                )}
              </Select.Option>
            );
          })}
        </Select.OptGroup>
      ) : (
        listDataOptionsRender.map((item, index) => (
          <Select.Option
            key={item._id}
            value={`${item.slug}-${item.shortId ? item.shortId : item._id}`}
            title={item.title}>
            <Link
              href={`/project/${item.slug}-${
                item.shortId ? item.shortId : item._id
              }`}
              onClick={() => {
                handleSaveSearchHistory(item);
                setFocus(false);
              }}>
              <AvatarWithName
                name={item.title}
                textClass="text-fs-14 font-medium text-grey-800"
                subTitleClass="text-fs-12 font-normal text-grey-500"
                subTitle={convertAddress(item)}
                imageClass="!object-contain"
                src={item.logo?.uri ?? `/${DEFAULT_LOGO_PROJECT}`}
                imageProps={{
                  resizeOnFly: item.logo?.uri ? true : false,

                  resizeOptions: {
                    width: 0,
                    height: 60,
                  },
                }}
              />
            </Link>
          </Select.Option>
        ))
      )}
    </Select>
  );
};
export default SearchProject;
